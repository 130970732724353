// dark theme
body.theme-dark {
  // common
  --colorPrimary: #005b50;
  --colorSecondary: #b6b6b6;
  --colorText: #f6f6f6;
  --colorWhite: #ffffff;
  --colorBlack: #000000;
  // Lavender
  --colorLavenderL10: #f4efff;
  --colorLavenderL20: #ece2ff;
  --colorLavenderL50: #e3d6ff;
  --colorLavenderL100: #d6c8fe;
  --colorLavenderL200: #af7fe7;
  --colorLavenderL300: #8e5cbb;
  --colorLavenderL500: #724E93;
  --colorLavenderL700: #5F4180;
  --colorLavenderL800: #4d3369;
  --colorLavenderL700dim: #5f418099;
  --colorLavenderL700dim80: #5F4180CC;
  --colorLavenderL500dim: #724E9380;
  // Menthol
  --colorMentholM100: #003d3699;
  --colorMentholM400: #4f867c;
  --colorMentholM500: #2e756d;
  --colorMentholM600: #005b50;
  --colorMentholM700: #00483f;
  --colorMentholM800: #003d36;
  // Neutral
  --colorNeutralN10: #fbfbfb;
  --colorNeutralN20: #f6f6f6;
  --colorNeutralN50: #cecece;
  --colorNeutralN100: #919191;
  --colorNeutralN200: #838383;
  --colorNeutralN400: #696969;
  --colorNeutralN600: #4b4b4b;
  --colorNeutralN700: #3f3f3f;
  --colorNeutralN800: #393939;
  --colorNeutralN900: #363636;
  // Blue
  --colorBlueB10: #e3eeff;
  --colorBlueB50: #BBE1FA;
  --colorBlueB100: #3282B8;
  --colorBlueB200: #0F4C75;
  // Semantic
  --colorSemanticError: #c94444;
  --colorSemanticWarning: #D7A411;
  --colorSemanticSuccess: #4d9d04;
  --colorSemanticInfo: #5E41C6;
  --colorSemanticWarningDim: #a87f00;
  --colorSemanticErrorDim: #7c2929;
  // MUI
  --colorMuiError: #f44336;
  // Half-transparent
  --colorAlphaW10: rgba(255,255,255,0.1);
  --colorAlphaW30: rgba(255,255,255,0.3);
  --colorAlphaW40: rgba(255,255,255,0.4);
  --colorAlphaW50: rgba(255,255,255,0.5);
  --colorAlphaW60: rgba(255,255,255,0.6);
  --colorAlphaW80: rgba(255, 255, 255, 0.8);

  --colorAlphaB50: rgba(0,0,0,0.5);
  --colorAlphaB70: rgba(0,0,0,0.7);
}
// light theme
:root {
  color-scheme: light;
  // common
  --colorPrimary: #005b50;
  --colorSecondary: #393939;
  --colorText: #1f1f1f;
  --colorWhite: #ffffff;
  --colorBlack: #000000;
  // Lavender
  --colorLavenderL10: #3c2952;
  --colorLavenderL20: #452f60;
  --colorLavenderL50: #573b7a;
  --colorLavenderL100: #7c58a4;
  --colorLavenderL200: #af7fe7;
  --colorLavenderL300: #d6c8fe;
  --colorLavenderL500: #e3d6ff;
  --colorLavenderL700: #ece2ff;
  --colorLavenderL800: #f0e9ff;
  --colorLavenderL700dim: #ECE2FF99;
  --colorLavenderL700dim80: #ECE2FFCC;
  --colorLavenderL500dim: #E3D6FF80;
  // Menthol
  --colorMentholM100: #003d36;
  --colorMentholM400: #018675;
  --colorMentholM500: #47948b;
  --colorMentholM600: #53a69e;
  --colorMentholM700: #7eb9b0;
  --colorMentholM800: #003d3699;
  // Neutral
  --colorNeutralN10: #262626;
  --colorNeutralN20: #363636;
  --colorNeutralN50: #393939;
  --colorNeutralN100: #3f3f3f;
  --colorNeutralN200: #4b4b4b;
  --colorNeutralN400: #6e6e6e;
  --colorNeutralN600: #9b9b9b;
  --colorNeutralN700: #cecece;
  --colorNeutralN800: #f6f6f6;
  --colorNeutralN900: #fbfbfb;
  // Blue
  --colorBlueB10: #0F4C75;
  --colorBlueB50: #3282B8;
  --colorBlueB100: #BBE1FA;
  --colorBlueB200: #e3eeff;
  // Semantic
  --colorSemanticError: #f34646;
  --colorSemanticWarning: #D7A411;
  --colorSemanticSuccess: #5BB708;
  --colorSemanticInfo: #5E41C6;
  --colorSemanticWarningDim: #D7A411;
  --colorSemanticErrorDim: #da3e3e;
  // MUI
  --colorMuiError: #f44336;
  --colorAlphaW80: rgba(255,255,255,0.1);
  --colorAlphaW60: rgba(255,255,255,0.3);
  --colorAlphaW50: rgba(255,255,255,0.4);
  --colorAlphaW40: rgba(255,255,255,0.5);
  --colorAlphaW30: rgba(255,255,255,0.6);
  --colorAlphaW10: rgba(255, 255, 255, 0.8);
}